<script>
  import getElementOffset from '@/helpers/getElementOffset'

  /**
   * A panel providing information closely or tangentially related to the main content; can optionally be dismissed by the user.
   */
  export default {
    props: {
      /** The background color of the panel. */
      color: {
        type: String,
        default: 'gray-lightest',
        validator: (value) =>
          ['gray-lightest', 'primary', 'secondary', 'green', 'warning', 'white'].includes(value),
      },
      /** Whether the panel is dismissible. A dismissible panel has a X button in its top right corner, which emits a `dismiss` event when clicked. */
      dismissible: {
        type: Boolean,
        default: false,
      },
      /** A reference to a component instance or a DOM element that the panel should point toward. */
      arrowAnchor: {
        type: [Object, Element],
        default: undefined,
      },
      /** The size of the icon, in Tailwind units. */
      iconSize: {
        type: Number,
        default: 6,
      },
    },
    data() {
      return {
        arrowSide: null,
        arrowPosition: null,
      }
    },
    computed: {
      arrowAnchorElement() {
        if (!this.arrowAnchor) {
          return null
        }
        if (this.arrowAnchor.$el) {
          return this.arrowAnchor.$el
        }
        return this.arrowAnchor
      },
      arrowStyle() {
        if (this.arrowPosition === null) {
          return {}
        }
        return {
          left: `${this.arrowPosition}px`,
        }
      },
    },
    watch: {
      arrowAnchorElement() {
        this.refreshArrow()
      },
    },
    mounted() {
      this.refreshArrow()
      window.addEventListener('resize', this.refreshArrow)
    },
    updated() {
      this.refreshArrow()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.refreshArrow)
    },
    methods: {
      refreshArrow() {
        if (!this.$el || !this.arrowAnchorElement) {
          this.arrowSide = null
          this.arrowPosition = null
          return
        }
        const arrowWidth = 20
        const safetyMargin = 10
        const panelOffset = getElementOffset(this.$el)
        const arrowAnchorOffset = getElementOffset(this.arrowAnchorElement)
        this.arrowSide = panelOffset.top > arrowAnchorOffset.top ? 'top' : 'bottom'
        this.arrowPosition = Math.max(
          safetyMargin,
          Math.min(
            panelOffset.width - arrowWidth - safetyMargin,
            arrowAnchorOffset.left + arrowAnchorOffset.width / 2 - arrowWidth / 2 - panelOffset.left
          )
        )
      },
      dismiss() {
        /** Emitted when the X button is clicked. */
        this.$emit('dismiss')
      },
    },
  }
</script>

<template>
  <!-- for purgecss: panel--gray-lightest panel--primary panel--secondary panel--green panel--warning panel--white -->
  <div :class="`panel panel--${color} p-4 rounded-sm border`">
    <div
      v-if="arrowSide !== null"
      :class="`panel-arrow absolute ${
        arrowSide === 'top' ? 'bottom-full triangle-up' : 'top-full triangle-down'
      } text-xl`"
      :style="arrowStyle"
    />
    <div class="flex items-baseline">
      <BaseIcon
        v-if="$scopedSlots.icon"
        :size="iconSize"
        class="mr-2 -mt-2 flex-shrink-0"
        :style="{ top: $pxToRem(6) }"
      >
        <!-- @slot Optional. An icon shown on the left of the panel’s content. Should contain an SVG icon component. -->
        <slot name="icon" />
      </BaseIcon>
      <div class="flex-1 font-normal text-base leading-snug">
        <BaseIconClickable
          v-if="dismissible"
          label="Dismiss"
          :size="4"
          :color="null"
          class="z-10 group float-right -mr-2 ml-2 -mt-2 mb-1"
          @click="dismiss"
        >
          <span
            class="absolute inset-0 rounded-sm bg-black opacity-0 group-hover:opacity-10 group-active:opacity-20 transition group-active:transition-none duration-200"
          />
          <IconX />
        </BaseIconClickable>
        <!-- @slot The panel’s content. -->
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .panel {
    &--gray-lightest {
      @apply bg-gray-lightest text-gray-darkest;

      .panel-arrow {
        @apply text-gray-lightest;
      }
    }

    &--primary {
      @apply bg-primary text-white;

      .panel-arrow {
        @apply text-primary;
      }
    }

    &--secondary {
      @apply bg-secondary text-white;

      .panel-arrow {
        @apply text-secondary;
      }
    }

    &--green {
      @apply bg-green text-white;

      .panel-arrow {
        @apply text-green;
      }
    }

    &--warning {
      @apply bg-warning-light text-black;

      .panel-arrow {
        @apply text-warning-light;
      }
    }

    &--white {
      @apply border-gray-active bg-white text-black;

      .panel-arrow {
        @apply text-gray-light;
      }
    }
  }
</style>
