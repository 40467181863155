/**
 * Gets a DOM element’s size and position relative to the document.
 */

export default (element) => {
  let left = 0
  let top = 0
  const width = element.offsetWidth
  const height = element.offsetHeight

  do {
    left += element.offsetLeft
    top += element.offsetTop
    element = element.offsetParent
  } while (element)

  return {
    left,
    top,
    width,
    height,
  }
}
